/*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
	*/
	.block-colon { display: none !important;}
@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {

	/* Force table to not be like tables anymore */
	table.tr_select,
	.tr_select thead,
	.tr_select tbody,
	.tr_select th,
	.tr_select td,
	.tr_select tr {
		display: block;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	.tr_select thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	.tr_select tr,
	.prop_search_table tr {
		margin: 0 0 1rem 0;
	}

	.tr_select tr:nth-child(odd),
	.prop_search_table tr:nth-child(odd) {
		// background: #ccc;
	}

	.tr_select td {
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding-left: 50% !important;
	}

	.tr_select td:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 12px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
	}

	/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
	.tr_select td:nth-of-type(1):before {
		content: "Emp Code :";
	}

	.tr_select td:nth-of-type(2):before {
		content: "Title :";
	}

	.tr_select td:nth-of-type(3):before {
		content: "First Name : *";
	}

	.tr_select td:nth-of-type(4):before {
		content: "Last Name : *";
	}

	.tr_select td:nth-of-type(5):before {
		content: "Grade :";
	}

	.tr_select td:nth-of-type(6):before {
		content: "Designation :";
	}

	.tr_select td:nth-of-type(7):before {
		content: "Email Id :";
	}

	.tr_select td:nth-of-type(8):before {
		content: "Mobile Number :";
	}

	.tr_select td:nth-of-type(9):before {
		content: "Action :";
	}


/* availability */

.tr_select.availability td:nth-of-type(1):before {
	content: "Room Type :";
}

.tr_select.availability td:nth-of-type(2):before {
	content: "Room Details :";
}

.tr_select.availability td:nth-of-type(3):before {
	content: "Room No :";
}

.tr_select.availability td:nth-of-type(4):before {
	content: "Bed No :";
}

/* booking details*/

.tr_select.booking_details td:nth-of-type(1):before {
	content: "Booking No :";

}

.tr_select.booking_details td:nth-of-type(2):before {
	content: "Guest Name :";
}

.tr_select.booking_details td:nth-of-type(3):before {
	content: "Grade :";
}

.tr_select.booking_details td:nth-of-type(4):before {
	content: "Occupancy	 :";
}

.tr_select.booking_details td:nth-of-type(5):before {
	content: "Room No :";
}

.tr_select.booking_details td:nth-of-type(6):before {
	content: "Check-In Date :";
}

.tr_select.booking_details td:nth-of-type(7):before {
	content: "Check-Out Date :";
}

.tr_select.booking_details td:nth-of-type(8):before {
	content: "Check In :";
}
/* booking details*/

.tr_select.roomblock td:nth-of-type(1):before {
	content: "Room No ";

}

.tr_select.roomblock td:nth-of-type(2):before {
	content: "From Date ";
}

.tr_select.roomblock td:nth-of-type(3):before {
	content: "To Date ";
}

.tr_select.roomblock td:nth-of-type(4):before {
	content: "Comments ";
}
.tr_select.roomblock td:nth-of-type(5):before {
	content: "Unblock All ";
}

.tr_select.roomblock td:nth-of-type(6):before {
	content: "Modify ";
}

.tr_select.roomblock td:nth-of-type(7):before {
	content: "Unblock ";
}

.blockbut,.roomblock .toppings-list .rs-checkbox { width:100% !important; }
.block-colon { display: inline-block !important;}


}
.tr_select td:before {
	font-weight: bold;
	line-height: 15px;
}

.empTable td:before {
	font-weight: bold;
}




/*******************************custom css ***************************/
.trBlock .o_flow.h_100 {
	height: 100% !important;
	box-shadow: 0px 0px 5px #ddd;
	padding-top: 5px;
}

@media only screen and (max-width: 760px) {

	.tr_list_table,
	#EmpCode {
		width: 100%;
	}

	.t_left {
		text-align: left;
	}

	.custom-control-sm .custom-control-label::after {
		left: -1.625rem;
		width: 1.5125rem;
		height: 1.55rem;
		border: 1px solid #ddd;
		top: 0px;
	}
}

@media only screen and (min-width: 768px) {
	.tr_list_table th {
		background: #2c85fe;
		padding: 7px 2px;
		color: #fff;
		font-weight: normal;
		font-size: 13px;
		text-align: center;
		border-right: 1px solid #7caff4;

		&:first-child {
			border-radius: 5px 0px 0px 5px;
		}

		&:last-child {
			border-radius: 0px 5px 5px 0px;
			border-right: 0px;
		}
	}
}

.popup_Close {
	padding: 2px 9px;
	margin-top: -4px;
	border-radius: 13px;
	height: 26px;

	&:hover {
		cursor: pointer;
		background: #ff0000 !important;
		color: #fff;
	}
}

@media only screen and (max-width: 1200px) {
	.nk-menu-trigger.left_menu img {
		//display: none !important;
	}
}